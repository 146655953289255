import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap'; // eslint-disable-next-line
import default_avatar from '../../assets/img/team/avatar.svg';
import { rightSideMenuList } from '../../routes'; // eslint-disable-next-line
import Avatar from '../common/Avatar'; // eslint-disable-next-line
import { copiriAxiosPost } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
import { toast } from 'react-toastify';
import loader_image from '../../assets/img/loader/small_loader.gif'; // eslint-disable-next-line
import axios from 'axios';
const ProfileDropdown = props => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [isUserProfile, setIsUserProfile] = useState(false);
	const [pageContent, setPageContent] = useState([]);
	const toggle = () => setDropdownOpen(prevState => !prevState);
	const [userProfile, setUserProfile] = useState();
	/* initial api request to get the user profile data*/
	useEffect(() => {
		if (props.profileDropDownData.avatar) {
			setIsUserProfile(true);
			setUserProfile(props.profileDropDownData.avatar);
			localStorage.setItem('parent_name', props.profileDropDownData.org_name);
		} else {
			setIsUserProfile(false);
		}
		if (props.langContetn.AMU) {
			setPageContent(props.langContetn.AMU);
		}
		// eslint-disable-next-line
	}, [props]);

	const getRouteName = (name, to) => {
		if (pageContent) {
			for (var key in pageContent) {
				if (pageContent[key].toLowerCase() === name.toLowerCase()) {
					return pageContent[key];
				}
			}
		}
		return name;
	};

	return (
		<Dropdown
			nav
			inNavbar
			isOpen={dropdownOpen}
			toggle={toggle}
			onMouseOver={() => {
				let windowWidth = window.innerWidth;
				windowWidth > 992 && setDropdownOpen(true);
			}}
			onMouseLeave={() => {
				let windowWidth = window.innerWidth;
				windowWidth > 992 && setDropdownOpen(false);
			}}
		>
			<DropdownToggle nav className='pr-0'>
				{isUserProfile ? (
					<div className='avatar avatar-xl '>
						<img className='rounded-circle ' id='userProfileImage' src={userProfile} alt='' />
					</div>
				) : (
					<div className='avatar avatar-xl '>
						<img className='rounded-circle ' id='userProfileImage' src={loader_image} alt='' />
					</div>
				)}
			</DropdownToggle>
			<DropdownMenu right className='dropdown-menu-card' style={{ height: '600px', overflowX: 'auto' }}>
				<div className='bg-white rounded-soft py-2'>
					{rightSideMenuList &&
						rightSideMenuList.map((route, index) => {
							return (
								<div key={index}>
									<DropdownItem tag={Link} to={route.to}>
										<span className='nav-link-icon pr-2'>
											<i className={route.icon}></i>
										</span>
										{getRouteName(route.name, route.to)}
									</DropdownItem>
									{route.divider && <DropdownItem divider />}
								</div>
							);
						})}
				</div>
			</DropdownMenu>
		</Dropdown>
	);
};

export default ProfileDropdown;
