/* eslint react/no-multi-comp: 0, react/prop-types: 0 */ // eslint-disable-next-line
import React, { useState, useEffect } from 'react';

// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'; // eslint-disable-next-line
import FormGroupInput from '../../common/FormGroupInput'; // eslint-disable-next-line
import { copiriAxiosPost, copiriAxiosGet,copiriPortalRequest } from '../../api/api'; // eslint-disable-next-line
import config from '../../config/config'; // eslint-disable-next-line
import { toast } from 'react-toastify';
import CopiriLoader from '../../common/CopiriLoader';
import default_org_img from '../../../assets/img/logos/no_gl_seal_light.png';// eslint-disable-next-line
const TransactioDetailModal = props => {
	const { transcId,pageLanguageContent } = props; //get prop data
	const [transactionData, setTransactionData] = useState([]);
	const [showLoader, setShowLoader] = useState(false);
	useEffect(() => {
		if (props.showHideTransactionModal) {
			// call fucntion the get the transaction detail data
			getTransactionDetailData(transcId);
		}
		// eslint-disable-next-line
	}, [props]);

	/* function to call API to get transaction detail data */
	function getTransactionDetailData(transcId) {
		setShowLoader(true);
		setTransactionData([]);
		copiriPortalRequest('/payments/transactions/' + transcId, config.app_version, 'get')
		.then(response => {
			if (response.data && response.data.status) {
				setTransactionData(response.data);
			}
			setShowLoader(false);
		})
		.catch(error => {
			toast.error(error.response.data.result)
			setShowLoader(false);
			props.hideTransactionModal();
		});
	}
	return (
		<div>			
			<Modal isOpen={props.showHideTransactionModal} toggle={props.hideTransactionModal} size='lg' id="lodge_modal">
				<ModalHeader toggle={props.hideTransactionModal}>
					{pageLanguageContent['PMT018'] ? pageLanguageContent['PMT018'] : ''}
				</ModalHeader>
				{showLoader ? (
					<CopiriLoader />
				) : (
					<>
						<ModalBody>
							<Row className=''>
								<Col lg={12} md={12} sm={12} >
									<h5 className='mb-2 p-3' style={{textAlign:'center'}}>
										{
											transactionData.org_seal?
												<img className='rounded-circle' width="45" src={transactionData.org_seal} alt='' />
											:
												<img className='rounded-circle' width="45" src={default_org_img} alt='' />
										}
										<span style={{marginLeft:'10px'}}>{transactionData.status?transactionData.status:''}</span>
									</h5>
								</Col>					
							</Row>
						</ModalBody>
					</>
				)}
				<ModalFooter>
					<Button color='secondary' onClick={props.hideTransactionModal}>
						{pageLanguageContent['PMT019'] ? pageLanguageContent['PMT019'] : ''}
					</Button>
				</ModalFooter>
			</Modal>		
		</div>
	);
};

export default TransactioDetailModal;
