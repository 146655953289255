// eslint-disable-next-line
import React, { useState, useEffect } from 'react'; // eslint-disable-next-line
import { copiriPortalRequest, copiriPortalBankingRequest, sessionLogout } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
import loader_image from '../../assets/img/loader/small_loader.gif'; // eslint-disable-next-line
import Logo from '../navbar/Logo'; // eslint-disable-next-line
import { Link } from 'react-router-dom'; // eslint-disable-next-line
import { Media } from 'reactstrap'; // eslint-disable-next-line
import CopiriLoader from '../common/CopiriLoader';
import Footer from '../footer/Footer';
import PaymentHistoryModal from './PaymentHistoryModal';
import { Redirect } from 'react-router-dom';
import BankingDashboardTree from './BankingDashboardTree'; // eslint-disable-next-line
const BankingManagement = props => {
	// eslint-disable-next-line
	const [sellerId, setSellerId] = useState();
	const [showLoader, setShowLoader] = useState(true); // eslint-disable-next-line
	const [checkAPI, setCheckAPI] = useState(true); // eslint-disable-next-line
	const [paymentData, setPaymentData] = useState([]); // eslint-disable-next-line
	const [checkLoader, setCheckLoader] = useState([]); // eslint-disable-next-line
	const [inValidateLink, setInValidateLink] = useState(false);
	const [pageLanguageContentData, setPageLanguageContentData] = useState([]);
	const [showHide, setShowHide] = useState(false);
	var checkSession = localStorage.getItem('palaver');
	useEffect(() => {
		getLanguagePack();
		// eslint-disable-next-line
	}, []);

	function getLanguagePack() {
		if (checkSession) {
			setShowLoader(true);
			var checkData = JSON.parse(localStorage.getItem('idiom'));
			var lang_id = 177394503;
			var url = '/languages/' + lang_id + '/package/PRT/PUB';
			copiriPortalRequest(url, config.app_version, 'get')
				.then(response => {
					document.title = response.data?.result?.PUB?.PUB004;
				})
				.catch(error => {});
			if (checkData?.BNK?.HME) {
				setPageLanguageContentData(checkData.BNK.HME);
				getBankingInfoData();
			} else {
				storelanguageTranslatedText(lang_id);
			}
		}
	}

	/* function to get/set the translated text in local storage*/
	function storelanguageTranslatedText(lang_id) {
		copiriPortalRequest('/languages/' + lang_id + '/package/BNK', config.app_version, 'get')
			.then(response => {
				setShowLoader(false);
				if (response?.data?.result?.HME) {
					setPageLanguageContentData(response.data.result.HME);
					let publicTextData = JSON.parse(localStorage.getItem('idiom'));
					let pubArr = publicTextData ? publicTextData : {};
					var storeTranslatedText = {};
					storeTranslatedText['BNK'] = response.data.result;
					let cancatArr = Object.assign({}, storeTranslatedText, pubArr);
					localStorage.setItem('idiom', JSON.stringify(cancatArr));
				}
				getBankingInfoData();
			})
			.catch(error => {
				getBankingInfoData();
			});
	}

	// eslint-disable-next-line
	function getBankingInfoData() {
		setPaymentData([]);
		setShowLoader(true);
		setCheckAPI(true);
		var user_id = localStorage.getItem('user_id');
		if (user_id && user_id !== null && user_id !== undefined) {
			copiriPortalBankingRequest('/web/users/' + user_id + '/banking/accounts', config.app_version, 'get', '', true)
				.then(response => {
					setShowLoader(false);
					setCheckAPI(false);
					if (response.data.banking.length > 0) {
						setPaymentData(response.data.banking);
					}
				})
				.catch(error => {
					setShowLoader(false);
					setCheckAPI(false);
				});
		} else {
			sessionLogout();
		}
	}

	/* fucntion to show hide the child rows */
	function showHideChildRows(id, item,show_hide) {
		let showChildRows = 'block';
		if (show_hide==='hide') {
			showChildRows = 'none';
		}
		if (item.children && item.children.length>0) {
			for (let i=0;i<item.children.length;i++) {
				let child_id = item.children[i]['id'];
				document.getElementById("banking_child_row_"+child_id).style.display = showChildRows;
			}
		}
		if (show_hide==='show') {
			document.getElementById("show_hide_right_"+id).style.display = "none";
			document.getElementById("show_hide_down_"+id).style.display = "block";
		} else {
			document.getElementById("show_hide_right_"+id).style.display = "block";
			document.getElementById("show_hide_down_"+id).style.display = "none";
		}
	}
	const getStripeLink = id => {
		setInValidateLink(true);
		setCheckLoader({
			...checkLoader,
			[id]: true
		});
		copiriPortalRequest('/sellers/' + id + '/onboard/link', config.app_version, 'get')
			.then(response => {
				if (response.data.url) {
					window.open(response.data.url, '_blank');
				} else {
					alert(pageLanguageContentData['HME006']);
				}
				setTimeout(() => {
					setCheckLoader([]);
					setInValidateLink(false);
				}, 5000);
			})
			.catch(error => {
				if (!error.response.status === 401 || !error.response.status === 440) {
					alert(pageLanguageContentData['HME006']);
				}
				setTimeout(() => {
					setCheckLoader([]);
					setInValidateLink(false);
				}, 5000);
			});
	};

	/* function to show modal */
	const showModal = () => {
		setShowHide(!showHide);
	};
	/* function to close modal */
	const closeModal = () => {
		setShowHide(!showHide);
	};
	/* function to update functionality on modal */
	const updateModal = index => {
		setShowHide(!showHide);
	};

	if (!checkSession) {
		return <Redirect to='/authentication/basic/login' />;
	}

	return (
		<div className='banking_site_layout'>
			<div className='row'>
				<div style={{ textAlign: 'center', marginTop: '10px' }} className='col-12 loginLogo col'>
					<Logo at='navbar-top' width={40} id='topLogo' style={{ marginLeft: '15px' }} />
				</div>
			</div>
			{showLoader ? (
				<CopiriLoader />
			) : (
				<div className='flex-center pt-2 mb-pading '>
					<div className='payment-baniking-dev'>
						<div className='p-0 shadownone card'>
							<div className='fs--1 font-weight-normal p-0 card-body'>
								<h5 className='mb-0' style={{ backgroundColor: '#fff', padding: '10px 15px' }}>
									<i className='fas fa-landmark'></i>{' '}
									<span style={{ marginLeft: '5px' }}>
										{pageLanguageContentData['HME007'] ? pageLanguageContentData['HME007'] : ''}
									</span>
								</h5>
							</div>
							<div className='accountCOntent' style={{ padding: '0px' }}>
								{paymentData && paymentData.length > 0 && (
									<BankingDashboardTree
										paymentData={paymentData}
										getStripeLink={getStripeLink}
										checkLoader={checkLoader}
										inValidateLink={inValidateLink}
										pageLanguageContentData={pageLanguageContentData}
										showModal={showModal}
										showHideChildRows={showHideChildRows}
									/>
								)}
							</div>
							{!checkAPI && paymentData && paymentData.length <= 0 && (
								<div className='flex-center row'>
									<div style={{ textAlign: 'center' }} className='col-12 loginLogo col'>
										<h5 className='fs--1' style={{ textAlign: 'center' }}>
											{pageLanguageContentData['HME005'] ? pageLanguageContentData['HME005'] : ''}
										</h5>
									</div>
								</div>
							)}
							<div className='flex-center row'>
								<div style={{ textAlign: 'center', marginTop: '20px' }} className='col-12  col'>
									<Link to='/authentication/basic/logout'>
										<span
											className='nav-link-text'
											style={{ fontSize: '14px', fontWeight: '600', cursor: 'pointer', color: '' }}
										>
											{pageLanguageContentData['HME004'] ? pageLanguageContentData['HME004'] : ''}
										</span>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			<Footer />
			<PaymentHistoryModal showHide={showHide} onClick={closeModal} updateModal={updateModal} pageLanguageContentData={pageLanguageContentData} />
		</div>
	);
};
export default BankingManagement;
